'use client'

import { FEATURE_FLAG_APP_ENABLED, FEATURE_FLAG_REGISTRY_ENABLED } from '@/config/feature-flags'
import { CalendarDaysIcon, ChatBubbleLeftRightIcon, ComputerDesktopIcon, MapIcon, PhoneIcon, UsersIcon } from '@heroicons/react/24/outline'
import { useLoginStatus } from '@tebuto/functions'
import COMPONENT_TEXTS from '@tebuto/translations'
import { Menu, MenuEntry } from '../Menu'

export const Header = () => {
    const isLoggedIn = useLoginStatus()

    const navigation: MenuEntry[] = [
        { name: COMPONENT_TEXTS.layout.landing.menu.home, href: '/' },
        {
            name: COMPONENT_TEXTS.layout.landing.menu.usage,
            href: '/#termin-management',
            subMenu: {
                items: [
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.appointments.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.appointments.subtitle,
                        href: '/#termin-management',
                        icon: CalendarDaysIcon
                    },
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.clientLinks.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.clientLinks.subtitle,
                        href: '/#passwortlose-klienten-links',
                        icon: UsersIcon
                    },
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.communication.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.communication.subtitle,
                        href: '/#kommunikation-automatisieren',
                        icon: ChatBubbleLeftRightIcon
                    },
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.directory.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.directory.subtitle,
                        href: '/#verzeichnis',
                        icon: ComputerDesktopIcon
                    }
                ],
                cta: [
                    { name: 'Rückruf vereinbaren', href: '/kontakt', icon: PhoneIcon },
                    { name: 'Roadmap anzeigen', href: '/roadmap', icon: MapIcon }
                ]
            }
        },
        { name: COMPONENT_TEXTS.layout.landing.menu.price, href: '/#preis' },
        { name: COMPONENT_TEXTS.layout.landing.menu.blog, href: '/blog' },
        { name: COMPONENT_TEXTS.layout.landing.menu.contact, href: '/kontakt' }
    ]

    if (FEATURE_FLAG_REGISTRY_ENABLED) {
        navigation.push({ name: COMPONENT_TEXTS.layout.landing.menu.directory, href: '/verzeichnis' })
    }

    if (FEATURE_FLAG_APP_ENABLED) {
        if (isLoggedIn) {
            navigation.push({ name: COMPONENT_TEXTS.layout.landing.toApp, href: process.env.NEXT_PUBLIC_APP_BASE_URL, isAbsolute: true })
        } else {
            navigation.push({ name: COMPONENT_TEXTS.layout.landing.menu.login, href: '/login', id: 'login' })
        }
    }

    return <Menu items={navigation} baseUrl={`${process.env.NEXT_PUBLIC_LANDING_BASE_URL}`} />
}
